import lazyload from './lazyload';

/**
 *
 *  Loading states:
 *  - initial load
 *  - DOMContentLoaded
 *  - DOMContentLoaded + first user activity
 *  - window load
 *
 *  Code Splitting with Parcel:
 *  - https://en.parceljs.org/code_splitting.html
 *
 *  Other notes:
 *  - There's some weird stuff going on here but we are squeezing as much
 *  performance as possible out of this while at the same time keeping
 *  some older code.
 *
 */

const dynamicImports = {
  critical: [
    { name: 'overlayManager', bundle: import('./overlay-manager') },
    { name: 'navOnScroll', bundle: import('./nav-on-scroll') },
    { name: 'menuList', bundle: import('./menu-list') },
  ],
  firstUserActivity: [
    { name: 'videoPlayer', bundle: null },
    { name: 'loopingVideo', bundle: null },
    { name: 'officeTour', bundle: null },
    { name: 'bmiCalculator', bundle: null },
    { name: 'gallerySlider', bundle: null },
  ],
  nonCritical: [
    { name: 'allForms', bundle: import('./forms/all-forms') },
    { name: 'googleAnalytics', bundle: import('./thesis/google-analytics') },
    { name: 'referrer', bundle: import('./thesis/referrer') },
    { name: 'landingPageCookie', bundle: import('./landing-page-cookie') },
    { name: 'iframeWrapper', bundle: import('./iframe-wrapper') },
    { name: 'previewMode', bundle: null },
  ],
};

async function loadModules({ name, bundle }) {
  // Lazily load the requested module.
  // "m" is for "module"
  let m;
  // Some bundles are set to null because they would always load even if their
  // conditionals weren't met.
  if (bundle !== null) {
    m = await bundle;
  }

  // Putting some import statements inside coniditionals to ensure we are only
  // loading code that we want to load.
  if (name === 'videoPlayer') {
    const isVideoPage = document.body.classList.contains('video-page');

    if (isVideoPage) {
      const videoContainers = [document.body];
      m = await import('./video-player');
      m.default(videoContainers);
      return;
    }

    const videoContainers = [
      ...document.getElementsByClassName('video-section'),
    ];
    const usePlyr = videoContainers.some(
      (c) => !c.querySelector('iframe[data-src*="youtube"]')
    );

    if (videoContainers.length && usePlyr) {
      m = await import('./video-player');
      m.default(videoContainers);
      return;
    }

    return;
  }

  if (name === 'loopingVideo') {
    const loopingVideos = [...document.querySelectorAll('video.looping-video')];
    if (loopingVideos.length) {
      console.log('loading loopingVideos...');
      m = await import('./looping-video');
      m.default(loopingVideos);
    }
    return;
  }

  if (name === 'officeTour') {
    const officeTours = [
      ...document.getElementsByClassName('office-tour-section'),
    ];
    if (officeTours.length) {
      m = await import('./office-tour');
      m.default(officeTours);
    }
    return;
  }

  if (name === 'bmiCalculator') {
    const bmiCalculators = [...document.getElementsByClassName('BMI-calc')];
    if (bmiCalculators.length) {
      m = await import('./bmi-calculator');
      m.default(bmiCalculators);
    }
    return;
  }

  if (name === 'gallerySlider') {
    const gallerySliders = [
      ...document.getElementsByClassName('einstiny-slider'),
    ];
    if (gallerySliders.length) {
      m = await import('./gallery-slider');
      m.default(gallerySliders);
    }
    return;
  }

  if (name === 'previewMode') {
    if (window.location.search.includes('page_version=preview')) {
      m = await import('./thesis/preview-mode');
      m.default();
    }
    return;
  }

  m.default();
}

/**
 *
 *  Do things if Lucid UI loads
 *
 */
function observeLucidAppLoaded() {
  // The node that will be observed for mutations
  const target = document.querySelector('html');
  // Options for the observer (which mutations to observe)
  const config = { attributes: true };
  // Callback function to execute when mutations are observed
  const onLucidAppObserved = (mutationList, observer) =>
    mutationList.some((m) => {
      if (m.target.classList.contains('xlucid_apploaded')) {
        import('./lucid-ui/lazysizes-resize').then((m) => m.default());
        // Stop observing after first mutation
        observer.disconnect();
        return true;
      }
    });
  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(onLucidAppObserved);
  // Start observing the target node for configured mutations
  observer.observe(target, config);
}

/**
 *
 * init
 *
 */
function init() {
  const { critical, firstUserActivity, nonCritical } = dynamicImports;

  /**
   *
   * readyState === 'interactive'
   *
   */
  document.addEventListener('DOMContentLoaded', () => {
    lazyload.init();

    /**
     *
     * first user activity
     *
     */

    // An array of DOM events that should be interpreted as user activity.
    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];

    // register the activity function as the listener parameter.
    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleFirstUserActivity, {
        once: true,
      });
    });

    function handleFirstUserActivity() {
      firstUserActivity.forEach((dynamicImport) => loadModules(dynamicImport));

      activityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, handleFirstUserActivity);
      });
    }
  });

  /**
   *
   * readyState === 'complete'
   *
   */
  window.addEventListener('load', () => {
    nonCritical.forEach((dynamicImport) => loadModules(dynamicImport));
  });

  /**
   *
   * readyState === 'loading'
   *
   */
  lazyload.config();
  critical.forEach((dynamicImport) => loadModules(dynamicImport));
  observeLucidAppLoaded();
}

export default init;
