import lazySizes from 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

function config() {
  lazySizes.cfg.init = false;

  // calculated visual viewport area in all directions,
  // so that elements can be loaded before they become visible
  const innerWidth = window.innerWidth;
  // mobile
  if (innerWidth < 600) {
    lazySizes.loadMode = 1;
    // lazySizesConfig.expand = 100;
    // lazySizesConfig.expFactor = 1.25;
    // tablet
  } else if (innerWidth > 600 && innerWidth < 900) {
    lazySizesConfig.expand = 600;
    // desktop
  } else if (innerWidth > 900) {
    lazySizesConfig.expand = 900;
  }

  window.lazySizesConfig = lazySizes.cfg;
}

function init() {
  const images = [].slice.call(document.getElementsByTagName('img'));

  // For Puppeteer-Crawler: manually load all images ASAP
  if (navigator.userAgent === 'Puppeteer-Crawler/1.0') {
    images.forEach((image) => image.classList.add('lazypreload'));
    const bgImages = [].slice.call(document.querySelectorAll('[data-bg]'));
    bgImages.forEach((image) => {
      image.style.backgroundImage = `url("${image.dataset.bg}")`;
      image.removeAttribute('data-bg');
    });

    lazySizes.init();
    return;
  }

  if (images.length > 0) {
    images.forEach((image) => image.classList.add('lazyload'));
  }

  lazySizes.init();
}

export default { init, config };
